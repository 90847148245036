import logo from './logo.svg';
import './App.css';
import TextStyler from './components/home';
import {BrowserRouter,Routes,Route} from 'react-router-dom'
import PrivacyPolicy from './components/privacyPolicy';
import Termsofuse from './components/terms-of-use';
import Contact from './components/contactUs';
import TextStyler2 from './components/HomeNoText';
import About from './components/about';
import Error from './components/Error';
import Blog from './components/blog';
function App() {
  return (
    <div className="App">
         <BrowserRouter>
         <Routes>
          <Route path="/blog" element={<Blog/>}/>
          <Route path="/" element={<TextStyler2/>}/>

          <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
          <Route path="/terms-of-use" element={<Termsofuse/>}/>
          <Route path="/contact-us" element={<Contact/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/*" element={<Error/>}/>



          </Routes>
          </BrowserRouter>
    </div>
  );
}

export default App;
