import React from 'react'
import Header from './header'
import Footer from './footer'

const PrivacyPolicy = () => {
  return (
    <div>
        <Header></Header>
        <div>
        مرحبا بكم في zakhrafatasmafreefire.zelobrix.com الموقع المتخصص في زخرفة الأسماء الأولى باللغتين الإنجليزية والعربية. نحن ملتزمون بحماية خصوصيتك وفهم أهمية البيانات التي تشاركها معنا. نوضح في سياسة الخصوصية هذه ما هي المعلومات التي قد نجمعها عنك عند استخدامك لموقعنا وكيف نستخدم هذه المعلومات.



جمع واستخدام المعلومات:

يصرح موقع zakhrafatasmafreefire.zelobrix.com أنه لا يقوم بجمع أي بيانات شخصية أو معلومات خاصة من زواره. جميع الخدمات المقدمة في الموقع متاحة للزوار دون الحاجة للتسجيل أو إدخال أي بيانات شخصية. يمكن للزوار استخدام الموقع وتزيين أسمائهم بسرية تامة دون القلق على خصوصيتهم.



بسكويت:

ونود أن نشير إلى أن موقع zakhrafatasmafreefire.zelobrix.com قد يستخدم ملفات تعريف الارتباط لتحسين تجربة المستخدم وتسهيل التنقل في الموقع. لا تجمع ملفات تعريف الارتباط هذه معلومات شخصية ولا يمكن استخدامها لأغراض تحديد الهوية.



أمن البيانات:

نظرًا لأن zakhrafatasmafreefire.zelobrix.com لا يقوم بجمع البيانات الشخصية، فلم يعد من الضروري اتخاذ تدابير أمنية واسعة النطاق للبيانات. ومع ذلك، فإننا ملتزمون بحماية الموقع وضمان عدم تعرضه للتهديدات الأمنية.



تغييرات سياسة الخصوصية:

نحن نحتفظ بالحق في تعديل أو تحديث سياسة الخصوصية هذه في أي وقت وفقًا للمتطلبات القانونية أو التغييرات التي تطرأ على خدماتنا. سيتم نشر أي تغييرات على سياسة الخصوصية على هذه الصفحة حتى تكون متاحة لجميع مستخدمينا.



اتصل بنا:

إذا كان لديك أي أسئلة أو أسئلة بخصوص سياسة الخصوصية هذه، يرجى الاتصال بفريق إدارة موقع zakhrafatasmafreefire.zelobrix.com عبر عنوان البريد الإلكتروني المقدم.



يعرب موقع zakhrafatasmafreefire.zelobrix.com عن شكره لزواره على ثقتهم ويعدهم بتقديم تجربة آمنة ومأمونة.
        </div>
        <Footer></Footer>
    </div>
  )
}

export default PrivacyPolicy