import React, { useState } from 'react';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="text-gray-600 body-font">
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        {/* Logo */}
       

        {/* Desktop Navigation */}
        <nav className="hidden md:flex md:ml-auto md:mr-auto items-center text-base justify-center">
          <a className="mr-5 hover:text-gray-900" href="/contact-us"> &nbsp; تواصل معنا</a>
          <span className="border-l h-6 mx-2 border-gray-400"></span>
          <a className="mr-5 hover:text-gray-900" href="/privacy-policy" > &nbsp;&nbsp;سياسة الخصوصية</a>
          <span className="border-l h-6 mx-2 border-gray-400"></span>
          <a className="mr-5 hover:text-gray-900" href="/terms-of-use" > &nbsp;&nbsp;سياسة المستخدم</a>
          <span className="border-l h-6 mx-2 border-gray-400"></span>

          <a className="mr-5 hover:text-gray-900 my-2" href="/blog" > &nbsp;&nbsp; المدونة</a>

          <span className="border-l h-6 mx-2 border-gray-400"></span>
          <a className="mr-5 hover:text-gray-900" href="/">&nbsp;&nbsp;الرئيسية</a>
        </nav>
        <a href="/" className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
          <img src="/images/logo-zakh.png" width="100px" height="30px" alt="https://zakhrafatfreefire.online/" />
        </a>
        {/* Mobile Menu Button */}
        <button
          className="md:hidden ml-auto focus:outline-none"
          onClick={toggleMenu}
        >
          <svg className="w-6 h-6 text-gray-900" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            {isOpen ? (
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            ) : (
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
            )}
          </svg>
        </button>

        {/* Mobile Menu */}
        <div className={`${isOpen ? 'flex' : 'hidden'} md:hidden flex-col w-full mt-4`}>
        <a className="mr-5 hover:text-gray-900 my-2" href="/">الرئيسية</a>
        <a className="mr-5 hover:text-gray-900 my-2" href="/blog">المدونة</a>
          <a className="mr-5 hover:text-gray-900 my-2" href="/privacy-policy">سياسة الخصوصية</a>
          <a className="mr-5 hover:text-gray-900 my-2" href="/terms-of-use">سياسة المستخدم</a>
          <a className="mr-5 hover:text-gray-900 my-2" href="/contact-us">تواصل معنا</a>
          
        </div>
      </div>
    </header>
  );
};

export default Header;
