import React, { useState, useEffect, useRef } from 'react';
import './TextStyler.css';
import Header from './header';
import Footer from './footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TextStyler2 = () => {
  const [text, setText] = useState('FreeFire');
  const [styledTexts, setStyledTexts] = useState([]);
  const [visibleTexts, setVisibleTexts] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const textRef = useRef(null);

  useEffect(() => {
    const fetchStyledTexts = async () => {
      try {
        const pages = [
          'New', 'Unique', 'CoolText', 'Elegant', 'Lunicodes', 'Squiggle', 'Symbolic',
          'Freaky', 'BoxText', 'StarDecorated', 'HeartDecorated', 'DecoratedText',
          'JoinerText', 'EmoticonText', 'LineText', 'CuteText', 'CrazyText',
          'FullCrazyText', 'RandomText', 'Halloween', 'Christmas'
        ];
        const pagesQueryString = pages.map(page => `pages%5B%5D=${page}`).join('&');

        const url = `https://www.fancytextpro.com/generate?text=${encodeURIComponent(text)}&_csrf=&${pagesQueryString}`;

        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            text: text,
            _csrf: '',
            pages: pages,
          }),
        });

        const result = await response.json();
        console.log('Response:', result); // Log the response

        // Extracting all styles from the result object
        const stylesArray = Object.values(result);
        setStyledTexts(stylesArray);
        setVisibleTexts(stylesArray.slice(startIndex, startIndex + 25));

      } catch (error) {
        console.error('Error fetching styled texts:', error);
      }
    };

    fetchStyledTexts();
  }, [text, startIndex]);

  const handleCopy = (textToCopy) => {
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    toast.success('Text copied to clipboard!');
  };

  const handleCopyStyledText = (styledText) => {
    handleCopy(styledText);
  };

  const handleLoadMore = () => {
    const newIndex = startIndex + 25;
    setVisibleTexts([...visibleTexts, ...styledTexts.slice(newIndex, newIndex + 25)]);
    setStartIndex(newIndex);
  };

  const renderGridItems = () => {
    const items = visibleTexts.map((styledText, index) => (
      <div key={index} className="style-item p-4 border rounded-lg shadow-sm">
        <span
          ref={textRef}
          dangerouslySetInnerHTML={{ __html: styledText }}
        ></span>
        <button
          onClick={() => handleCopyStyledText(styledText)}
          className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          Copy
        </button>
      </div>
    ));

    // Inserting separator text between groups of 25
    const renderedItems = [];
    items.forEach((item, index) => {
      renderedItems.push(item);
      if ((index + 1) % 25 === 0 && index !== items.length - 1) {
        renderedItems.push(<div key={`separator-${index}`} className="text-center mt-4 mb-4">--- Separator Text ---</div>);
      }
    });

    return renderedItems;
  };

  return (
    <>
      <Header />
      <hr />
      <div className="text-styler">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">
      زخرفة اسماء فري فاير
        </h1>
        <div>
       
            الكثير منا يريد زخرفة اسمه حتى يصبح ذات شكل جميل ويجذب الإنتباه
            وخصوصا الاشخاص الذين يستخدمون لعبة فري فاير (Free Fire) يحبون ان
            يكون اسم البروفايل الخاص بهم مزخرف حتى يكون جميل ولكن الكثير منا
            لايعرف طريقة الزخرفة ويقوم بتنزيل العديد من البرامج المزعجة
            والمضره لذالك أنت في المكان المناسب زخرفة الاسماء بدون برامج. طريقة
            زخرفة الاسم فقط ضع الاسم الذي تريد زخرفته وسيتم الزخرفة في الحال
            وبشكل سريع وايضا يوجد آلاف من اشكال الزخرفة وليس شكل واحد فقط.
       
        </div>
        <br />
        <div> زخرفة الاسماء هي عملية تزيين الاسماء بأشكال ورموز مختلفة لجعلها أكثر
          جمالا وتميزا، و هي تستخدم في الكتابة على الإنترنت أو في التصاميم
          الفنية أو في الهدايا والدعوات. هذه الزخرفات تعتمد على الذوق الشخصي
          والابداع للشخص الذي يقوم بها، ولكن هناك بعض القواعد والنصائح التي يجب
          مراعاتها للحصول على نتائج جيدة. من بعض هذه القواعد هي:
          <ul>
            <li>
              اختيار نوع الخط المناسب للاسم والغرض من زخرفته: هناك أنواع كثيرة
              من الخطوط العربية، مثل الثلث، والنسخ، والكوفي، والديواني،
              والرقعة، وغيرها. كل نوع من هذه الخطوط له خصائصه وجماله الخاص،
              ويناسب بعض الأسماء أكثر من غيرها. على سبيل المثال، خط الثلث يناسب
              الأسماء التي تحتوي على حروف مستديرة مثل "حمزة" أو " رانيا"، بينما
              الخط الكوفي يناسب الأسماء التي تحتوي على حروف مربعة مثل "خالد"
              أو "فاطمة".
            </li>
            <li>
              اضافة بعض الزخارف والرموز التي تزيد من جمالية الاسم وتبرز معانيه:
              هناك زخارف كثيرة يمكن استخدامها في زخرفة الأسماء، مثل الورود،
              والنجوم، والقلوب، والحجارة الكريمة، وغيرها. كما يمكن استخدام بعض
              الرموز التي ترتبط بالاسم أو صاحبه، مثل رمز المهنة أو الهواية أو
              المدينة أو التاريخ.
            </li>
            <li>
              مراعاة التناسق والتناغم بين عناصر الزخرفة والاسم: يجب أن تكون زخرفة
              الاسم متناسقة مع حجمه وشكله ولونه، ولا تغطي على قراءته أو تشتت
              انتباه المشاهد.
            </li>
          </ul></div>
          <br></br>
         :ادخل اسمك
        <input
          type="text"
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Enter text"
          className="w-full px-4 py-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 sm:text-sm mb-4"
        />

        <div className="grid grid-cols-1">
          {renderGridItems()}
        </div>
        {styledTexts.length > visibleTexts.length && (
          <div className="mt-4">
            <button
              onClick={handleLoadMore}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              عرض المزيد
            </button>
          </div>
        )}
      </div>
      <hr />
      <div>
        :هذه امثلة للاسماء التي يطلبها الكثير من اللاعبين
<br></br>
 
        زخرفة اسماء فري فاير,
إسم queen مزخرف فري فاير,
إسم الجوكر مزخرف فري فاير,
إسم جنرال مزخرف فري فاير,
إسم جوكر مزخرف فري فاير,
إسم سلطان مزخرف فري فاير,
إسم عسولة مزخرف فري فاير,
إسم فارس مزخرف فري فاير,
إسم مزخرف فري فاير,
اجمل زخرفة اسماء فري فاير,
احرف كبيرة فري فاير,
احسن زخرفة اسماء فري فاير,
اسم الجوكر مزخرف فري فاير,
اسم حزين مزخرف فري فاير,
اسم فخامة مزخرف فري فاير,
اسم فري فاير مخفي,


      </div>
      <Footer />
      <ToastContainer />
    </>
  );
};

export default TextStyler2;
