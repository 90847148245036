import React from 'react'
import Footer from './footer'
import Header from './header'

const Termsofuse = () => {
  return (
    <div>
        <Header></Header>
        <div>
        سياسة الاستخدام
مرحباً بكم في موقع zakhrafatasmafreefire.zelobrix.com، وهو موقع ينشر الصحف باللغتين الإنجليزية والعربية. نحن فخورون بتقديم خدماتنا للمبادئ التالية:



1. أدخل:

باستخدام موقع zakhrafatasmafreefire.zelobrix.com، فإنك توافق على هذه الشروط والأحكام. إذا كنت لا توافق على هذه الشروط والأحكام، يرجى عدم استخدام الموقع.



2. استخدام الموقع:

zakhrafatasmafreefire.zelobrix.com هو موقع يمكن لأي شخص استخدامه دون تسجيل أو تسجيل. يقدم هذا الموقع خدمات لأغراض غير تجارية.



3. القوة العقلية:

جميع العلامات التجارية والرسومات والشعارات والرسومات وما إلى ذلك الموجودة على zakhrafatasmafreefire.zelobrix.com هي ملكية حصرية لـ zakhrafatasmafreefire.zelobrix.com أو الشركات التابعة لها ومحمية بموجب حقوق الطبع والنشر.



4. لا ينطبق:

يحظر استخدام zakhrafatasmafreefire.zelobrix.com لأي غرض غير قانوني أو محظور بموجب هذه الشروط. يتضمن ذلك، على سبيل المثال لا الحصر، الرسائل الخبيثة أو الضارة أو البريد العشوائي.



5. قم بتغيير الرسالة:

قد تقوم zakhrafatasmafreefire.zelobrix.com بتغيير هذه الشروط والأحكام في أي وقت. وستكون هذه التغييرات سارية فور نشرها على الموقع.



6. إخلاء المسؤولية:

يقدم zakhrafatasmafreefire.zelobrix.com الخدمات "كما هي" دون أي ضمان من أي نوع، سواء كان صريحًا أو ضمنيًا. لا يضمن هذا الموقع دقة أو اكتمال الصفحات، ولا يتحمل أي مسؤولية عن أي أخطاء أو سهو.



7. القيود على العرض:

نظرًا لعدم وجود رسوم تسجيل، فإن zakhrafatasmafreefire.zelobrix.com لا يضمن مدة التشغيل. يمكن إيقاف العملية أو تغييرها
        </div>
        <Footer></Footer>
    </div>
  )
}

export default Termsofuse